import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useAuth } from "../hooks/useAuth";
import { Box, Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Paper, useTheme, useMediaQuery } from "@mui/material";
import bitcoinAPI from "../services/bitcoinAPI";
import stocksAPI from "../services/stocksAPI";

export default function Settings() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [bitcoinPrice, setBitcoinPrice] = useState({});
    const [walletBalance, setWalletBalance] = useState({});
    const [stocksPrice, setStocksPrice] = useState({});

    useEffect(() => {
        fetchBitcoinPrice();
        fetchWalletBalance();
        fetchStocksPrice();
    }, []);

    const fetchBitcoinPrice = async () => {
        const data = await bitcoinAPI.fetchLatestPrice();
        setBitcoinPrice(data);
    };

    const fetchWalletBalance = async () => {
        const data = await bitcoinAPI.fetchLatestWalletBalance();
        setWalletBalance(data);
    };

    const fetchStocksPrice = async () => {
        const data = await stocksAPI.fetchLatestPrice();
        setStocksPrice(data);
    };

    // Format the price with commas
    const formattedPrice = (price, currency, minimumFractionDigits, maximumFractionDigits) => new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: minimumFractionDigits,  // Hide decimal points
        maximumFractionDigits: maximumFractionDigits,  // Hide decimal points
    }).format(price);

    // Convert the bitcoinPrice.Time to a Date object and format it
    const formatTime = (time) => {
        return time ? new Date(time).toLocaleString('en-GB', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: false,
        }) : 'N/A';
    }

    return (
        <>
            <Helmet>
                <title>Settings</title>
            </Helmet>

            <Container maxWidth="xl">
                <Typography variant={isMobile ? "h6" : "h4"} gutterBottom>
                    Asset Overview
                </Typography>

                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell><strong>Asset</strong></TableCell>
                                <TableCell align="right"><strong>Value</strong></TableCell>
                                <TableCell align="right"><strong>Latest Time Fetched</strong></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {/* Bitcoin USD */}
                            <TableRow>
                                <TableCell>Bitcoin Price (USD)</TableCell>
                                <TableCell align="right">{formattedPrice(bitcoinPrice.BitcoinPriceUSD, 'USD', 0, 0)}</TableCell>
                                <TableCell align="right">{formatTime(bitcoinPrice.Time)}</TableCell>
                            </TableRow>

                            {/* Bitcoin EUR */}
                            <TableRow>
                                <TableCell>Bitcoin Price (EUR)</TableCell>
                                <TableCell align="right">{formattedPrice(bitcoinPrice.BitcoinPriceEUR, 'EUR', 0, 0)}</TableCell>
                                <TableCell align="right">{formatTime(bitcoinPrice.Time)}</TableCell>
                            </TableRow>

                            {/* Wallet Balance */}
                            <TableRow>
                                <TableCell>Wallet Balance</TableCell>
                                <TableCell align="right">{walletBalance.WalletBalance || 'N/A'}</TableCell>
                                <TableCell align="right">{formatTime(walletBalance.Time)}</TableCell>
                            </TableRow>

                            {/* BTC Sum */}
                            <TableRow>
                                <TableCell>BTC SUM</TableCell>
                                <TableCell align="right">{walletBalance.BTCSum || 'N/A'}</TableCell>
                                <TableCell align="right"></TableCell>
                            </TableRow>

                            {/* SXR8 */}
                            <TableRow>
                                <TableCell>SXR8 Price</TableCell>
                                <TableCell align="right">{formattedPrice(stocksPrice.SXR8, 'EUR', 2, 2) || 'N/A'}</TableCell>
                                <TableCell align="right">{formatTime(stocksPrice.Time)}</TableCell>
                            </TableRow>

                            {/* SXRV */}
                            <TableRow>
                                <TableCell>SXRV Price</TableCell>
                                <TableCell align="right">{formattedPrice(stocksPrice.SXRV, 'EUR', 2, 2) || 'N/A'}</TableCell>
                                <TableCell align="right">{formatTime(stocksPrice.Time)}</TableCell>
                            </TableRow>

                            {/* SXRV */}
                            <TableRow>
                                <TableCell>ZS Price</TableCell>
                                <TableCell align="right">{formattedPrice(stocksPrice.ZS, 'USD', 2, 2) || 'N/A'}</TableCell>
                                <TableCell align="right">{formatTime(stocksPrice.Time)}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Container>
        </>
    );
}