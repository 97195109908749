import { useEffect, useMemo, useState } from "react";
import { Outlet } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Box, Container } from "@mui/system";
import { Avatar, Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, IconButton, InputLabel, Menu, MenuItem, Paper, Select, Stack, SvgIcon, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Tooltip, Typography, styled, tableCellClasses, useMediaQuery, useTheme } from "@mui/material";
import investmentAPI from "../../services/investmentAPI";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import positionAPI from "../../services/positionAPI";


export default function Positions(props) {
    var { positions, positionCount, investmentUsers, positionTypes, assets, refresh, user, title, admin } = props;

    const [positionPage, setPositionPage] = useState(0);
    const [positionRowsPerPage, setPositionRowsPerPage] = useState(10);;
    const [newPosition, setNewPosition] = useState({
        User: "",
        Time: new Date(),
        Asset: "",
        Value: 0,
        Type: "",
    });
    const [openPositionModal, setOpenPositionModal] = useState(false);
    const [editPosition, setEditPosition] = useState({
        User: "",
        Time: new Date(),
        Asset: "",
        Value: 0,
        Type: "",
    });
    const [openEditPositionModal, setOpenEditPositionModal] = useState(false);
    const [openDeletePositionModal, setOpenDeletePositionModal] = useState(false);
    const [positionToDelete, setPositionToDelete] = useState(null);

    const handlePositionChangePage = (newPage) => {
        setPositionPage(newPage);
    };

    const handlePositionChangeRowsPerPage = (newRowsPerPage) => {
        setPositionRowsPerPage(newRowsPerPage);
        setPositionPage(0);
    };

    const handleOpenPositionModal = () => {
        setOpenPositionModal(true);
    };

    const handleClosePositionModal = () => {
        setOpenPositionModal(false);
    };

    const handlePositionInputChange = (e) => {
        const { name, value } = e.target;
        setNewPosition({ ...newPosition, [name]: value });
    };

    const handlePositionSubmit = async () => {
        const date = new Date(newPosition.Time);
        const data = {
            User: newPosition.User,
            Time: date.toISOString(),
            Asset: newPosition.Asset,
            Value: parseFloat(newPosition.Value),
            Type: newPosition.Type
        };
        console.log(data);
        await positionAPI.createPosition(data);
        refresh(positionPage, positionRowsPerPage); // Refresh positions after creating a new one
        handleClosePositionModal();
    };

    const handleOpenEditPositionModal = (position) => {
        setEditPosition(position);  // Store the position to be edited
        setOpenEditPositionModal(true);  // Open the modal
    };

    const handleUpdatePosition = async () => {
        if (!editPosition) return;

        const updatedData = {
            User: editPosition.User,
            Time: new Date(editPosition.Time).toISOString(),
            Asset: editPosition.Asset,
            Value: parseFloat(editPosition.Value),
            Type: editPosition.Type
        };

        await positionAPI.updatePosition(editPosition.ID, updatedData);
        refresh(positionPage, positionRowsPerPage);  // Refresh the list after the update
        setOpenEditPositionModal(false);
    };

    const handleOpenDeletePositionModal = (position) => {
        setPositionToDelete(position);
        setOpenDeletePositionModal(true);
    };

    const handleDeletePosition = async () => {
        if (!positionToDelete) return;

        await positionAPI.deletePosition(positionToDelete.ID);
        refresh(positionPage, positionRowsPerPage);
        setOpenDeletePositionModal(false);
    };

    useEffect(() => {
        if (investmentUsers.length > 0) {
            const selectedUser = investmentUsers.find(user => user === title) || null;
            setNewPosition((prev) => ({ ...prev, User: selectedUser }));
        }
    }, [investmentUsers]);

    return (
        <>
            {user.Email == admin && (
                <Button variant="contained" color="primary" onClick={handleOpenPositionModal} sx={{ mb: 2 }}>
                    Create New Position
                </Button>
            )}
            <Dialog open={openPositionModal} onClose={handleClosePositionModal}>
                <DialogTitle>Create New Position</DialogTitle>
                <DialogContent>
                    <FormControl fullWidth margin="dense">
                        <InputLabel>User</InputLabel>
                        <Select
                            label="User"
                            name="User"
                            value={newPosition.User}
                            onChange={handlePositionInputChange}
                        >
                            {investmentUsers.map((user) => (
                                <MenuItem key={user} value={user}>
                                    {user}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField
                        margin="dense"
                        label="Time"
                        name="Time"
                        type="date"
                        value={new Date(newPosition.Time).toISOString().split('T')[0]}
                        onChange={(e) => {
                            const date = new Date(e.target.value);
                            setNewPosition({ ...newPosition, Time: date.toISOString() });
                        }}
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <FormControl fullWidth margin="dense">
                        <InputLabel>Asset</InputLabel>
                        <Select
                            label="Asset"
                            name="Asset"
                            value={newPosition.Asset}
                            onChange={handlePositionInputChange}
                        >
                            {assets.map((asset) => (
                                <MenuItem key={asset} value={asset}>
                                    {asset}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField
                        margin="dense"
                        label="Value"
                        name="Value"
                        type="number"
                        value={newPosition.Value}
                        onChange={handlePositionInputChange}
                        fullWidth
                    />
                    <FormControl fullWidth margin="dense">
                        <InputLabel>Type</InputLabel>
                        <Select
                            label="Type"
                            name="Type"
                            value={newPosition.Type}
                            onChange={handlePositionInputChange}
                        >
                            {positionTypes.map((type) => (
                                <MenuItem key={type} value={type}>
                                    {type}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClosePositionModal} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handlePositionSubmit} color="primary">
                        Create
                    </Button>
                </DialogActions>
            </Dialog>

            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>User</TableCell>
                            <TableCell>Time</TableCell>
                            <TableCell>Asset</TableCell>
                            <TableCell>Value</TableCell>
                            <TableCell>Type</TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {positions && positions.Data && positions.Data.map((position) => (
                            <TableRow key={position.ID}>
                                <TableCell>{position.User}</TableCell>
                                <TableCell>{new Date(position.Time).toISOString().split('T')[0]}</TableCell>
                                <TableCell>{position.Asset}</TableCell>
                                <TableCell>{position.Value.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 10 })}</TableCell>
                                <TableCell>{position.Type}</TableCell>
                                <TableCell>
                                    <IconButton
                                        onClick={() => handleOpenEditPositionModal(position)}
                                        aria-label="edit"
                                    >
                                        <EditIcon />
                                    </IconButton>
                                </TableCell>
                                <TableCell>
                                    <IconButton
                                        onClick={() => handleOpenDeletePositionModal(position)}  // Opens delete modal
                                        aria-label="delete"
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={positionCount}
                rowsPerPage={positionRowsPerPage}
                page={positionPage}
                onPageChange={handlePositionChangePage}
                onRowsPerPageChange={handlePositionChangeRowsPerPage}
            />


            <Dialog open={openEditPositionModal} onClose={() => setOpenEditPositionModal(false)}>
                <DialogTitle>Edit Position</DialogTitle>
                <DialogContent>
                    <FormControl fullWidth margin="dense">
                        <InputLabel>User</InputLabel>
                        <Select
                            label="User"
                            name="User"
                            value={editPosition.User}
                            onChange={(e) => setEditPosition({ ...editPosition, User: e.target.value })}
                        >
                            {investmentUsers.map((user) => (
                                <MenuItem key={user} value={user}>
                                    {user}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField
                        margin="dense"
                        label="Time"
                        name="Time"
                        type="date"
                        value={new Date(editPosition.Time).toISOString().split('T')[0]}
                        onChange={(e) => {
                            const date = new Date(e.target.value);
                            setEditPosition({ ...editPosition, Time: date.toISOString() });
                        }}
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <FormControl fullWidth margin="dense">
                        <InputLabel>Asset</InputLabel>
                        <Select
                            label="Asset"
                            name="Asset"
                            value={editPosition.Asset}
                            onChange={(e) => setEditPosition({ ...editPosition, Asset: e.target.value })}
                        >
                            {assets.map((asset) => (
                                <MenuItem key={asset} value={asset}>
                                    {asset}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField
                        margin="dense"
                        label="Value"
                        name="Value"
                        type="number"
                        value={editPosition.Value}
                        onChange={(e) => setEditPosition({ ...editPosition, Value: e.target.value })}
                        fullWidth
                    />
                    <FormControl fullWidth margin="dense">
                        <InputLabel>Type</InputLabel>
                        <Select
                            label="Type"
                            name="Type"
                            value={editPosition.Type}
                            onChange={(e) => setEditPosition({ ...editPosition, Type: e.target.value })}
                        >
                            {positionTypes.map((type) => (
                                <MenuItem key={type} value={type}>
                                    {type}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenEditPositionModal(false)} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleUpdatePosition} color="primary">
                        Save Changes
                    </Button>
                </DialogActions>
            </Dialog>


            <Dialog open={openDeletePositionModal} onClose={() => setOpenDeletePositionModal(false)}>
                <DialogTitle>Delete Position</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete the position "{positionToDelete?.Asset}" with a value of {positionToDelete?.Value}?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDeletePositionModal(false)} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleDeletePosition} color="primary">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}