import { useEffect, useMemo, useState } from "react";
import { Outlet } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Box, Container } from "@mui/system";
import { useAuth } from "../../hooks/useAuth";
import { Avatar, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, IconButton, InputLabel, MenuItem, Paper, Select, Stack, SvgIcon, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import CampaignIcon from '@mui/icons-material/Campaign';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import { useLocation } from 'react-router-dom';
import { capitalize } from 'lodash';
import investmentAPI from "../../services/investmentAPI";
import { Table, TableContainer, TableHead, TableRow, TableCell, TableBody, TablePagination } from '@mui/material';
import positionAPI from "../../services/positionAPI";
import assetAPI from "../../services/assetAPI";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

export default function Summary(props) {
    var { overallInvestments, totalValue, formatCurrency, formatPercentage, calculatePercentage } = props;

    return (
        <>
            <Grid container spacing={4} sx={{ mb: 4 }}>
                {/* Invested */}
                <Grid item xs={12} sm={4}>
                    <Box
                        sx={{
                            height: 150,
                            backgroundColor: '#f0f0f0',
                            borderRadius: 2,
                            boxShadow: 3,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            p: 2,
                        }}
                    >
                        <Typography variant="h6" color="textSecondary" sx={{ mb: 1 }}>
                            Invested
                        </Typography>
                        <Typography variant="h4" color="primary">
                            {formatCurrency(overallInvestments.Total, 'EUR', 0)}
                        </Typography>
                    </Box>
                </Grid>

                {/* Networth */}
                <Grid item xs={12} sm={4}>
                    <Box
                        sx={{
                            height: 150,
                            backgroundColor: '#f0f0f0',
                            borderRadius: 2,
                            boxShadow: 3,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            p: 2,
                        }}
                    >
                        <Typography variant="h6" color="textSecondary" sx={{ mb: 1 }}>
                            Networth
                        </Typography>
                        <Typography variant="h4" color="primary">
                            {formatCurrency(totalValue, 'EUR', 0)}
                        </Typography>
                    </Box>
                </Grid>

                {/* Gain/Losses */}
                <Grid item xs={12} sm={4}>
                    <Box
                        sx={{
                            height: 150,
                            backgroundColor: '#f0f0f0',
                            borderRadius: 2,
                            boxShadow: 3,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            p: 2,
                        }}
                    >
                        <Typography variant="h6" color="textSecondary" sx={{ mb: 1 }}>
                            Gain/Losses
                        </Typography>
                        <Typography variant="h4" color="primary">
                            {formatPercentage(calculatePercentage(overallInvestments.Total, totalValue))}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </>
    )

}