import { useEffect, useMemo, useState } from "react";
import { Outlet } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Box, Container } from "@mui/system";
import { useAuth } from "../hooks/useAuth";
import { Avatar, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, IconButton, InputLabel, MenuItem, Paper, Select, Stack, SvgIcon, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import CampaignIcon from '@mui/icons-material/Campaign';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import { useLocation } from 'react-router-dom';
import { capitalize } from 'lodash';
import investmentAPI from "../services/investmentAPI";
import { Table, TableContainer, TableHead, TableRow, TableCell, TableBody, TablePagination } from '@mui/material';
import positionAPI from "../services/positionAPI";
import assetAPI from "../services/assetAPI";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import InvestmentTable from "../components/investments/investments";
import FutureNetworth from "../components/networth/futureNetworth";
import Summary from "../components/networth/summary";
import Networth from "../components/networth/networth";
import Investments from "../components/investments/investments";
import Positions from "../components/positions/positions";

export default function Inputs() {
    const { user } = useAuth();
    const admin = "roccodonnarumma@gmail.com"
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const location = useLocation();
    const [assets, setAssets] = useState([]);
    const [overallInvestments, setOverallInvestments] = useState([]);
    const [overallPositions, setOverallPositions] = useState([]);

    const removeLeadingSlashAndCapitalize = (path) => {
        return capitalize(path.replace(/^\//, ''));
    };

    const title = useMemo(() => {
        return removeLeadingSlashAndCapitalize(location.pathname);
    }, [location.pathname]);

    const fetchAssets = async () => {
        const data = await assetAPI.fetchAssets();
        setAssets(data);
    };

    const fetchOverallInvestments = async () => {
        const data = await investmentAPI.fetchInvestmentsOverall(title);
        setOverallInvestments(data);
    };

    const fetchOverallPositions = async () => {
        const data = await positionAPI.fetchPositionsOverallByUser(title);
        setOverallPositions(data);
    };

    // investments
    const [investments, setInvestments] = useState([]);
    const [investmentCount, setInvestmentCount] = useState(0);
    const [investmentUsers, setInvestmentUsers] = useState([]);
    const [investmentTypes, setInvestmentTypes] = useState([]);

    const fetchInvestments = async (investmentPage, investmentRowsPerPage) => {
        const data = await investmentAPI.fetchInvestments(title, investmentPage, investmentRowsPerPage);
        setInvestments(data);
        setInvestmentCount(data.Total);
    };

    const fetchInvestmentUsers = async () => {
        const data = await investmentAPI.fetchAllInvestmentUsers();
        setInvestmentUsers(data);
    };

    const fetchInvestmentTypes = async () => {
        const data = await investmentAPI.fetchAllInvestmentTypes();
        setInvestmentTypes(data);
    };

    // positions
    const [positions, setPositions] = useState([]);
    const [positionCount, setPositionCount] = useState(0);
    const [positionTypes, setPositionTypes] = useState([]);

    const fetchPositions = async (positionPage, positionRowsPerPage) => {
        const data = await positionAPI.fetchPositions(title, positionPage, positionRowsPerPage);
        setPositions(data);
        setPositionCount(data.Total);
    };

    const fetchPositionTypes = async () => {
        const data = await positionAPI.fetchAllPositionTypes();
        setPositionTypes(data);
    };

    
    useEffect(() => {
        fetchInvestments(0, 10);
        fetchInvestmentUsers();
        fetchInvestmentTypes();
        fetchPositions(0, 10);
        fetchPositionTypes();
        fetchAssets();
        fetchOverallInvestments();
        fetchOverallPositions();
    }, []);

    const formatCurrency = (value, currency, maximumFractionDigits) => new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: maximumFractionDigits
    }).format(value);

    const formatPercentage = (value) =>
        new Intl.NumberFormat('en-US', {
            style: 'percent',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        }).format(value);

    // Calculate total value across all positions
    const totalValue = overallPositions ? overallPositions.reduce((acc, row) => acc + row.TotalValueEUR, 0) : 0;

    const calculatePercentage = (invested, currentValue) => {
        return (currentValue - invested) / invested;
    };

    return (
        <>
            <Helmet>
                <title>{title}</title>
            </Helmet>

            <Container maxWidth="xl">
                <Summary
                    overallInvestments={overallInvestments}
                    totalValue={totalValue}
                    formatCurrency={formatCurrency}
                    formatPercentage={formatPercentage}
                    calculatePercentage={calculatePercentage}
                />

                <Typography variant="h4" sx={{ mb: 2 }}>
                    Networth
                </Typography>
                <Networth 
                    overallPositions={overallPositions}
                    formatCurrency={formatCurrency}
                    totalValue={totalValue}
                />

                {user.FirstName == "Rocco" && (
                    <FutureNetworth />
                )}

                <Typography variant="h4" sx={{ mb: 2 }}>
                    Investments
                </Typography>
                <Investments
                    investments={investments}
                    investmentCount={investmentCount}
                    investmentUsers={investmentUsers}
                    investmentTypes={investmentTypes}
                    refresh={fetchInvestments}
                    user={user}
                    title={title}
                    admin={admin}
                />

                <Typography variant="h4" sx={{ mb: 2 }}>
                    Positions
                </Typography>
                <Positions
                    positions={positions}
                    positionCount={positionCount}
                    investmentUsers={investmentUsers}
                    positionTypes={positionTypes}
                    assets={assets}
                    refresh={fetchPositions}
                    user={user}
                    title={title}
                    admin={admin}
                />
            </Container>
        </>
    );
}