import { useEffect, useMemo, useState } from "react";
import { Outlet } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Box, Container } from "@mui/system";
import { useAuth } from "../../hooks/useAuth";
import { Avatar, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, IconButton, InputLabel, MenuItem, Paper, Select, Stack, SvgIcon, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import CampaignIcon from '@mui/icons-material/Campaign';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import { useLocation } from 'react-router-dom';
import { capitalize } from 'lodash';
import investmentAPI from "../../services/investmentAPI";
import { Table, TableContainer, TableHead, TableRow, TableCell, TableBody, TablePagination } from '@mui/material';
import positionAPI from "../../services/positionAPI";
import assetAPI from "../../services/assetAPI";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

export default function FutureNetworth() {
    const { user } = useAuth();
    const admin = "roccodonnarumma@gmail.com"
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const location = useLocation();
    const [assets, setAssets] = useState([]);
    const [overallInvestments, setOverallInvestments] = useState([]);
    const [overallPositions, setOverallPositions] = useState([]);

    const removeLeadingSlashAndCapitalize = (path) => {
        return capitalize(path.replace(/^\//, ''));
    };

    const title = useMemo(() => {
        return removeLeadingSlashAndCapitalize(location.pathname);
    }, [location.pathname]);

    return (
        <>
            <Typography variant="h4" sx={{ mb: 2 }}>
                Future Networth
            </Typography>
            <TableContainer component={Paper} sx={{ mb: 4 }}>
                <Table sx={{ minWidth: 650 }} aria-label="summary table">
                    <TableHead>
                        <TableRow>
                            <TableCell>User</TableCell>
                            <TableCell>Date</TableCell>
                            <TableCell>Total Value</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>

                    </TableBody>
                </Table>
            </TableContainer>

            <Typography variant="h4" sx={{ mb: 2 }}>
                Company Stock
            </Typography>
            <TableContainer component={Paper} sx={{ mb: 4 }}>
                <Table sx={{ minWidth: 650 }} aria-label="summary table">
                    <TableHead>
                        <TableRow>
                            <TableCell>User</TableCell>
                            <TableCell>Vesting Date</TableCell>
                            <TableCell>Value</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>

                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )

}