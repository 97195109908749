import { useEffect, useMemo, useState } from "react";
import { Outlet } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Box, Container } from "@mui/system";
import { useAuth } from "../../hooks/useAuth";
import { Avatar, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, IconButton, InputLabel, MenuItem, Paper, Select, Stack, SvgIcon, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import CampaignIcon from '@mui/icons-material/Campaign';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import { useLocation } from 'react-router-dom';
import { capitalize } from 'lodash';
import investmentAPI from "../../services/investmentAPI";
import { Table, TableContainer, TableHead, TableRow, TableCell, TableBody, TablePagination } from '@mui/material';
import positionAPI from "../../services/positionAPI";
import assetAPI from "../../services/assetAPI";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

export default function Networth(props) {
    var { overallPositions, totalValue, formatCurrency } = props;

    return (
        <>
            <TableContainer component={Paper} sx={{ mb: 4 }}>
                <Table sx={{ minWidth: 650 }} aria-label="summary table">
                    <TableHead>
                        <TableRow>
                            <TableCell>User</TableCell>
                            <TableCell>Asset</TableCell>
                            <TableCell>Total</TableCell>
                            <TableCell>Total Value</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {overallPositions && overallPositions.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell>{row.User}</TableCell>
                                <TableCell>{row.Asset}</TableCell>
                                <TableCell>{row.Total.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 10 })}</TableCell>
                                <TableCell>{formatCurrency(row.TotalValueEUR, 'EUR', 2)}</TableCell>
                            </TableRow>
                        ))}
                        <TableRow>
                            <TableCell colSpan={3} align="right"><strong>Total</strong></TableCell>
                            <TableCell>
                                <strong>{formatCurrency(totalValue, 'EUR', 2)}</strong>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )

}