import { useEffect, useMemo, useState } from "react";
import { Outlet } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Box, Container } from "@mui/system";
import { Avatar, Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, IconButton, InputLabel, Menu, MenuItem, Paper, Select, Stack, SvgIcon, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Tooltip, Typography, styled, tableCellClasses, useMediaQuery, useTheme } from "@mui/material";
import investmentAPI from "../../services/investmentAPI";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";


export default function Investments(props) {
    var { investments, investmentCount, investmentUsers, investmentTypes, refresh, user, title, admin } = props;

    const [investmentPage, setInvestmentPage] = useState(0);
    const [investmentRowsPerPage, setInvestmentRowsPerPage] = useState(10);
    const [newInvestment, setNewInvestment] = useState({
        User: "",
        Time: new Date(),
        Value: 0,
        Currency: "",
        Type: "",
    });
    const [openInvestmentModal, setOpenInvestmentModal] = useState(false);
    const [editInvestment, setEditInvestment] = useState({
        User: "",
        Time: new Date(),
        Value: 0,
        Currency: "",
        Type: "",
    });
    const [openEditInvestmentModal, setOpenEditInvestmentModal] = useState(false);
    const [openDeleteInvestmentModal, setOpenDeleteInvestmentModal] = useState(false);
    const [investmentToDelete, setInvestmentToDelete] = useState(null);

    const handleInvestmentChangePage = (newPage) => {
        setInvestmentPage(newPage);
    };

    const handleInvestmentChangeRowsPerPage = (newRowsPerPage) => {
        setInvestmentRowsPerPage(newRowsPerPage);
        setInvestmentPage(0);
    };

    const handleOpenInvestmentModal = () => {
        setOpenInvestmentModal(true);
    };

    const handleCloseInvestmentModal = () => {
        setOpenInvestmentModal(false);
    };

    const handleInvestmentInputChange = (e) => {
        const { name, value } = e.target;
        setNewInvestment({ ...newInvestment, [name]: value });
    };

    const handleInvestmentSubmit = async () => {
        const date = new Date(newInvestment.Time);
        const data = {
            User: newInvestment.User,
            Time: date.toISOString(),
            Value: parseFloat(newInvestment.Value),
            Currency: newInvestment.Currency,
            Type: newInvestment.Type
        };
        console.log(data);
        await investmentAPI.createInvestment(data);
        refresh(investmentPage, investmentRowsPerPage); // Refresh investments after creating a new one
        handleCloseInvestmentModal();
    };

    const handleOpenEditInvestmentModal = (investment) => {
        setEditInvestment(investment);
        setOpenEditInvestmentModal(true);
    };

    const handleUpdateInvestment = async () => {
        if (!editInvestment) return;

        const updatedData = {
            User: editInvestment.User,
            Time: new Date(editInvestment.Time).toISOString(),
            Value: parseFloat(editInvestment.Value),
            Currency: editInvestment.Currency,
            Type: editInvestment.Type
        };

        await investmentAPI.updateInvestment(editInvestment.ID, updatedData);
        refresh(investmentPage, investmentRowsPerPage);  // Refresh the list after the update
        setOpenEditInvestmentModal(false);
    };

    const handleOpenDeleteInvestmentModal = (investment) => {
        setInvestmentToDelete(investment);
        setOpenDeleteInvestmentModal(true);
    };

    const handleDeleteInvestment = async () => {
        if (!investmentToDelete) return;

        await investmentAPI.deleteInvestment(investmentToDelete.ID);
        refresh(investmentPage, investmentRowsPerPage);
        setOpenDeleteInvestmentModal(false);
    };

    useEffect(() => {
        if (investmentUsers.length > 0) {
            const selectedUser = investmentUsers.find(user => user === title) || null;
            setNewInvestment((prev) => ({ ...prev, User: selectedUser }));
        }
    }, [investmentUsers]);

    return (
        <>

            {user.Email == admin && (
                <Button variant="contained" color="primary" onClick={handleOpenInvestmentModal} sx={{ mb: 2 }}>
                    Create New Investment
                </Button>
            )}
            <Dialog open={openInvestmentModal} onClose={handleCloseInvestmentModal}>
                <DialogTitle>Create New Investment</DialogTitle>
                <DialogContent>
                    <FormControl fullWidth margin="dense">
                        <InputLabel>User</InputLabel>
                        <Select
                            label="User"
                            name="User"
                            value={newInvestment.User}
                            onChange={handleInvestmentInputChange}
                        >
                            {investmentUsers.map((user) => (
                                <MenuItem key={user} value={user}>
                                    {user}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField
                        margin="dense"
                        label="Time"
                        name="Time"
                        type="date"
                        value={new Date(newInvestment.Time).toISOString().split('T')[0]}
                        onChange={(e) => {
                            const date = new Date(e.target.value);
                            setNewInvestment({ ...newInvestment, Time: date.toISOString() });
                        }}
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <TextField
                        margin="dense"
                        label="Value"
                        name="Value"
                        type="number"
                        value={newInvestment.Value}
                        onChange={handleInvestmentInputChange}
                        fullWidth
                    />
                    <TextField
                        margin="dense"
                        label="Currency"
                        name="Currency"
                        value={newInvestment.Currency}
                        onChange={handleInvestmentInputChange}
                        fullWidth
                    />
                    <FormControl fullWidth margin="dense">
                        <InputLabel>Type</InputLabel>
                        <Select
                            label="Type"
                            name="Type"
                            value={newInvestment.Type}
                            onChange={handleInvestmentInputChange}
                        >
                            {investmentTypes.map((type) => (
                                <MenuItem key={type} value={type}>
                                    {type}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseInvestmentModal} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleInvestmentSubmit} color="primary">
                        Create
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={openEditInvestmentModal} onClose={() => setOpenEditInvestmentModal(false)}>
                <DialogTitle>Edit Investment</DialogTitle>
                <DialogContent>
                    <FormControl fullWidth margin="dense">
                        <InputLabel>User</InputLabel>
                        <Select
                            label="User"
                            name="User"
                            value={editInvestment?.User || ""}
                            onChange={(e) => setEditInvestment({ ...editInvestment, User: e.target.value })}
                        >
                            {investmentUsers.map((user) => (
                                <MenuItem key={user} value={user}>
                                    {user}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField
                        margin="dense"
                        label="Time"
                        name="Time"
                        type="date"
                        value={new Date(editInvestment?.Time).toISOString().split('T')[0]}
                        onChange={(e) => {
                            const date = new Date(e.target.value);
                            setEditInvestment({ ...editInvestment, Time: date.toISOString() });
                        }}
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <TextField
                        margin="dense"
                        label="Value"
                        name="Value"
                        type="number"
                        value={editInvestment?.Value || ""}
                        onChange={(e) => setEditInvestment({ ...editInvestment, Value: e.target.value })}
                        fullWidth
                    />
                    <TextField
                        margin="dense"
                        label="Currency"
                        name="Currency"
                        value={editInvestment.Currency}
                        onChange={(e) => setEditInvestment({ ...editInvestment, Currency: e.target.value })}
                        fullWidth
                    />
                    <FormControl fullWidth margin="dense">
                        <InputLabel>Type</InputLabel>
                        <Select
                            label="Type"
                            name="Type"
                            value={editInvestment.Type}
                            onChange={(e) => setEditInvestment({ ...editInvestment, Type: e.target.value })}
                        >
                            {investmentTypes.map((type) => (
                                <MenuItem key={type} value={type}>
                                    {type}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenEditInvestmentModal(false)} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleUpdateInvestment} color="primary">
                        Save Changes
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={openDeleteInvestmentModal} onClose={() => setOpenDeleteInvestmentModal(false)}>
                <DialogTitle>Delete Investment</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete the investment in "{investmentToDelete?.Currency}" with a value of {investmentToDelete?.Value}?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDeleteInvestmentModal(false)} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleDeleteInvestment} color="primary">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>

            <TableContainer component={Paper} sx={{ mb: 4 }}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>User</TableCell>
                            <TableCell>Time</TableCell>
                            <TableCell>Value</TableCell>
                            <TableCell>Currency</TableCell>
                            <TableCell>Type</TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {investments && investments.Data && investments.Data.map((investment) => (
                            <TableRow key={investment.ID}>
                                <TableCell>{investment.User}</TableCell>
                                <TableCell>{new Date(investment.Time).toISOString().split('T')[0]}</TableCell>
                                <TableCell>{investment.Value.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 10 })}</TableCell>
                                <TableCell>{investment.Currency}</TableCell>
                                <TableCell>{investment.Type}</TableCell>
                                <TableCell>
                                    <IconButton
                                        onClick={() => handleOpenEditInvestmentModal(investment)}
                                        aria-label="edit"
                                    >
                                        <EditIcon />
                                    </IconButton>
                                </TableCell>
                                <TableCell>
                                    <IconButton
                                        onClick={() => handleOpenDeleteInvestmentModal(investment)}
                                        aria-label="delete"
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={investmentCount}
                rowsPerPage={investmentRowsPerPage}
                page={investmentPage}
                onPageChange={handleInvestmentChangePage}
                onRowsPerPageChange={handleInvestmentChangeRowsPerPage}
            />
        </>
    );
}